import React from 'react';
import Img from 'gatsby-image';
import { SecondaryAnchorButton } from '../components/buttons';

export const findAssetByContentId = (assets, contentful_id) => {
  return assets.edges.find(x => x.node.contentful_id === contentful_id).node;
};

export const findImageByContentId = (assets, contentful_id) => {
  return assets.edges.find(x => x.node.contentful_id === contentful_id).node.localFile.childImageSharp;
}

export const findImageByUrl = (assets, url) => {
  return assets.edges
    .filter(x => x.node.fluid != null)
    .find(x => (x.node.fluid.src || '').startsWith(url))
    .node
    .localFile
    .childImageSharp;
}

export const getDownloadInfoByContentfulId = (assets, contentful_id) => {
  const node = assets.edges.find(x => x.node.contentful_id === contentful_id).node;
  return { title: node.title, url: node.localFile.publicURL };
}

export const richTextOptions = (assets) => ({
  renderNode: {
    'embedded-asset-block': (node) => {
      const contentful_id = node.data.target.sys.contentful_id;
      switch (node.data.target.fields.file["en-US"].contentType) {
        case 'application/pdf':
          const downloadInfo = getDownloadInfoByContentfulId(assets, contentful_id);
          return (<SecondaryAnchorButton href={downloadInfo.url}>
            {downloadInfo.title}
          </SecondaryAnchorButton>);
        default:
          const imgNode = findImageByContentId(assets, contentful_id);
          return <Img fluid={imgNode.fluid} alt={imgNode.description} />;
      }
    }
  }
});
