import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PageWithHeader from '../components/pageWithHeader';
import { richTextOptions } from '../helpers/contentfulHelpers';
import style from './insight.module.scss';

const Insight = ({ pageContext, path }) => {
  const { data, assets, defaultImageSrc } = pageContext;

  const image = data.image && data.image.localFile && data.image.childImageSharp && data.image.childImageSharp.fluid
    ? data.image.localFile.childImageSharp.fluid.src
    : defaultImageSrc;

  return (
    <PageWithHeader title={data.title} url={path} image={image} description={data.description.description} >
      <div className={style.contentContainer}>
        <article>
          <div>
            <div>
              By: {data.insight_author} on <time dateTime={data.dateCreated}>{new Date(data.dateCreated).toLocaleDateString()}</time>
            </div>
            <div>{documentToReactComponents(data.content.json, richTextOptions(assets))}</div>
          </div>
        </article>
      </div>
    </PageWithHeader>
  );
};

export default Insight;
